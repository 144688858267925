import React, { useState, useEffect } from 'react';
import { Loader2, ExternalLink, AlertCircle } from 'lucide-react';

const Alert = ({ children, variant = 'default' }) => {
    const bgColor = variant === 'destructive' ? 'bg-red-50' : 'bg-yellow-50';
    const textColor = variant === 'destructive' ? 'text-red-800' : 'text-yellow-800';

    return (
        <div className={`p-4 ${bgColor} ${textColor} rounded-lg shadow-sm`}>
            {children}
        </div>
    );
};

const TodayList = () => {
    const [today] = useState(() => new Date().toISOString().slice(0, 10));
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch(`https://raw.githubusercontent.com/maguowei/today/master/data/feeds/json/${today}.json`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setItems(data.reverse());
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchItems();
    }, [today]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen bg-gray-50">
                <Loader2 className="h-10 w-10 animate-spin text-blue-500" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen bg-gray-50">
                <Alert variant="destructive" className="max-w-md w-full">
                    <div className="flex items-center">
                        <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                        <div>
                            <h3 className="font-semibold">Error</h3>
                            <p className="text-sm">{error}</p>
                        </div>
                    </div>
                </Alert>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-4xl font-bold text-center mb-10 text-gray-900">多闻</h1>
                <ul className="space-y-4">
                    {items.map((item) => (
                        <li key={item.url} className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
                            <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block p-4 text-gray-800 hover:text-blue-600 transition-colors duration-200"
                            >
                                <div className="flex items-start">
                                    <ExternalLink className="h-5 w-5 mt-1 mr-3 flex-shrink-0 text-gray-400"/>
                                    <span className="text-lg font-medium leading-tight">
                                        {item.title}
                                    </span>
                                    <img
                                        src={item.source.icon}
                                        alt={item.source.name}
                                        width={16}
                                        height={16}
                                        className="ml-2"
                                    />
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TodayList;
